import React, { useEffect, useState, useRef, useMemo } from 'react';
import Tesseract from 'tesseract.js';
import classNames from "common/class-names";
import PropTypes from "prop-types";


const TextRecognition = (props, onExtractData) => {
  //const [selectedImage, setSelectedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [icNumber, setIcNumber] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const containerRef = useRef(null);
  const [ocrComplete, setOcrComplete] = useState({ ic: false, name: false, address: false });
  const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);


  const className = useMemo(() => {
		return classNames({
			"app-id-scan": true,
			"app-id-scan--outline": props.outline || false,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.outline]);


  //OCR Proccesing here
  const handleOCR = (image, coords) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.src = image;
    img.onload = () => {
      const container = containerRef.current;
      if(!container)
      {
        return;
      }
      const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();

      // Set canvas size to container size
      canvas.width = containerWidth;
      canvas.height = containerHeight;

      // Draw the image scaled to fit the container
      ctx.drawImage(img, 0, 0, containerWidth, containerHeight);

      // Add watermark
      //addWatermark(ctx, "EasyCover", containerWidth, containerHeight);

      // Use container-based coordinates for cropping
      const croppedImage = ctx.getImageData(coords.x, coords.y, coords.width, coords.height);
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = coords.width;
      tempCanvas.height = coords.height;
      tempCanvas.getContext('2d').putImageData(croppedImage, 0, 0);

      const croppedImageDataUrl = tempCanvas.toDataURL('image/jpeg');

      setIsProcessing(true);
      setOcrComplete(prev => ({ ...prev, ic: false }));
      setOcrComplete(prev => ({ ...prev, name: false }));
      setOcrComplete(prev => ({ ...prev, address: false }));

      Tesseract.recognize(
        croppedImageDataUrl,
        'eng',
        { logger: (m) => console.log(m) }
      ).then(({ data: { text } }) => {
        if (coords.label === 'IC') {

          // Use a regular expression to match all digits
          const numbersOnly = text.trim().match(/[\d-]+/g);

          // Join the matched numbers into a single string or process them as needed
          const result = numbersOnly ? numbersOnly.join('') : '';

          setIcNumber(result);
          setOcrComplete(prev => ({ ...prev, ic: true }));
        }
        if (coords.label === 'Name') {
          setName(text.trim());
          setOcrComplete(prev => ({ ...prev, name: true }));
        }
        if (coords.label === 'Address') {
          setAddress(text.trim());
          setOcrComplete(prev => ({ ...prev, address: true }));
        }
      })
    };
  };

  

  useEffect(() => {
    const processImage = (() => {
      if (!props.value || !containerRef.current) return;
      const container = containerRef.current;
      const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();
  
      // Define coordinates based on container size
      const icCoords = { x: containerWidth * 0.01, y: containerHeight * 0.17, width: containerWidth * 0.48, height: containerHeight * 0.15, label: 'IC' };
      const nameCoords = { x: containerWidth * 0.02, y: containerHeight * 0.60, width: containerWidth * 0.25, height: containerHeight * 0.21, label: 'Name' };
      const addressCoords = { x: containerWidth * 0.01, y: containerHeight * 0.55, width: containerWidth * 0.55, height: containerHeight * 0.44, label: 'Address' };
  
      handleOCR(props.value, icCoords);
      handleOCR(props.value, nameCoords);
      handleOCR(props.value, addressCoords);
  
    });

    if (props.value && containerRef.current && props.scan) {
      processImage();
    }
  }, [props.value, props.scan]);
  


  useEffect(() => {
    if (ocrComplete.ic && ocrComplete.name && ocrComplete.address && isProcessing) {
      props.onExtractData({ icNumber, name, address});
      setIsProcessing(false);
    }
  }, [ocrComplete, icNumber, name, address, props, isProcessing]);



  return (
      <div className={className} ref={containerRef} >
        <div className='app-id-scan__divide'>
          
          <input className={"app-id-scan__button"} type="file" accept="image/*" onChange={props.onChange} />
          {
            props.value ? (
              
              <img src={props.value} alt="Selected" className="app-id-scan__frontImage" />
            ) : (
                <p className="app-id-scan__placeholder">{props.placeholder}</p>
            )
          }
        </div>

        <div className='app-id-scan__error'>{errorMessage}</div>

      </div>
      
  );
};

// Define PropTypes for the component
TextRecognition.propTypes = {
  value: PropTypes.any,
  outline: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onExtractData: PropTypes.func,
  scan: PropTypes.bool.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default TextRecognition;